import { GetServerSideProps } from "next";

import { getStrapiClient } from "../context";
import { FEATURED_ARTICLE_ID } from "../config";
import { Article, DetailedArticle } from "../api/strapi/article";
import Layout from "../components/Layout";
import Featured from "../components/Featured";
import VideoList from "../components/VideoList";

interface Props {
  articles: Article[],
  featured: DetailedArticle,
}

export default function Home({ featured: { id, attributes }, articles }: Props) {
  return (<Layout>
    <Featured
      id={id}
      title={attributes.title}
      category={attributes.category.data}
      free={attributes.htw_video_price === 0}
      thumbnailUrl={attributes.image.data.attributes.url}
    >
      <div dangerouslySetInnerHTML={{ __html: attributes.introduction }} />
    </Featured>

    <VideoList
      title="Recente video's"
      articles={articles} />
  </Layout>)
}

export const getServerSideProps: GetServerSideProps = async () => {
  const strapi = getStrapiClient();
  const articles = await strapi.listArticles();
  const featured = await strapi.getArticle(FEATURED_ARTICLE_ID);

  return {
    props: {
      articles,
      featured,
    }
  };
}
