import React from "react";

import styles from "../styles/Featured.module.scss";
import Button from "./Button";
import VideoThumbnail from "./VideoThumbnail";
import { Category } from "../api/strapi/category";
import { slugify } from "../utils";

interface Props {
  id: number;
  title: string;
  category: Category;
  free: boolean;
  thumbnailUrl: string;
  children: React.ReactNode;
}

export default function Featured({ id, title, category, free = false, thumbnailUrl, children }: Props) {
  const slug = slugify(title);

  return (<div className={styles.featured}>
    <div className={styles.container}>
      <div className={styles.video_column}>
        <VideoThumbnail
          id={id}
          title={title}
          category={category}
          thumbnailUrl={thumbnailUrl}
          free={free} />
      </div>

      <div className={styles.intro_column}>
        <div className={styles.content}>
          <h2 className={styles.heading}>{title}</h2>
          <div className={styles.intro}>
            {children}
          </div>

          <p>
            <Button href={`/videos/${category.attributes.slug}/${id}/${slug}`} primary>{free ? "Kijk nu" : "Koop en kijk"}</Button>
          </p>
        </div>
      </div>
    </div>
  </div>)
}
